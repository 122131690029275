@use '@content' as *;
@use '@queries' as *;

.container {
  @include mainContentStyles;
  display: grid;
  width: 100%;
  padding: 12px 14px;
  gap: 8px;
  max-width: 1023px;
  justify-content: center;
  grid-template-columns: repeat(2, minmax(142px, 1fr));

  a {
    text-decoration: none;
    color: inherit;
    display: block;
  }

  @include min-428-break {
    grid-template-columns: repeat(2, minmax(193px, 1fr));
    gap: 14px;
    padding: 14px;
  }

  @include min-744-break {
    grid-template-columns: repeat(auto-fit, minmax(222px, 1fr));
    gap: 11px;
    padding: 14px 28px;
  }
}
