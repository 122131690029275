@use '@fixed-size' as *;
@use '@typography' as *;
@use '@content' as *;
@use '@tabs' as *;
@use '@queries' as *;

.content-widget {
  &__categories {
    @include mainContentStyles;
    display: grid;
    gap: 8px;
    padding: 12px 14px;
    grid-template-columns: repeat(1, 100%);
    justify-content: center;

    @include min-428-break {
      padding: 14px;
      gap: 14px;
    }

    @include min-744-break {
      gap: 12px;
      padding: 14px 28px;
      grid-template-columns: repeat(2, 338px);
    }
  }
}
